<template>
    <v-dialog v-model="dialog" persistent width="700">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="isNew" rounded v-on="on" v-bind="attrs" color="green" depressed class="white--text"
                @click="fetchData">
                <v-icon>mdi-plus</v-icon>
                {{ $t("Add Question to LMS") }}
            </v-btn>
            <v-icon v-if="!isNew" v-on="on" v-bind="attrs" color="blue" @click="fetchData">mdi-pen</v-icon>
        </template>
        <v-card>
            <v-card-title class="justify-center">
                <h1>{{ isNew ? $t("Add Question to LMS") : $t('edit') }}</h1>
            </v-card-title>
            <v-card-text class="py-10">
                <v-row>
                    <v-col md="4">
                        <v-autocomplete outlined dense :dark="$store.state.isDarkMode" v-model="structure.unit_id"
                            :items="units" :label="$t('Unit')" item-text="name" item-value="id"
                            color="var(--bg-color--font)"></v-autocomplete>
                    </v-col>
                    <v-col md="4">
                        <v-text-field outlined dense :dark="$store.state.isDarkMode" :label="$t('Time')"
                            v-model="structure.time"></v-text-field>
                    </v-col>
                    <v-col md="4">
                        <v-text-field outlined dense :dark="$store.state.isDarkMode" :label="$t('Mark')"
                            v-model="structure.mark"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="12">
                        <v-row style=" color: var(--bg-color--font) !important;">{{ $t('Question Text') }}</v-row>
                        <v-row style="margin-top: 3%; margin-left: 1%;">
                            <textarea style="width: 100%; border: 0.5px solid var(--bg-color--font);
                                border-radius: 20px; padding: 10px; color: var(--bg-color--font) !important;"
                                v-model="structure.name" rows="3">s
                            </textarea>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-row style="margin: 2%; color: var(--bg-color--font) !important;">{{ $t('Answers') }}</v-row>
                    <v-container id="buffet-table" fluid tag="section">
                        <v-simple-table :dark="$store.state.isDarkMode" style=" width: max-content;">
                            <tr>
                                <th>{{ $t('Correct') }}</th>
                                <th id="idAutoCompA">{{ $t('Answer') }}</th>
                                <th id="idAutoComp">{{ $t('explain') }}</th>
                                <th></th>
                            </tr>
                            <v-fade-transition mode="out-in" :group="true" tag="tbody">
                                <tr v-for="(el, ix) in structure.answers" :key="ix">
                                    <td>
                                        <v-checkbox :dark="$store.state.isDarkMode" v-model="el.is_correct" :true-value="1"
                                            :false-value="0" class="mx-4 mb-4" hide-details></v-checkbox>
                                    </td>
                                    <td>
                                        <v-text-field full-width dense hide-details flat color="#757575" v-model="el.name"
                                            @keydown.enter="ToNextInput"></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field @keydown.enter="addNewAnswer" full-width type="text" dense
                                            hide-details flat color="#757575" v-model="el.desc"></v-text-field>
                                    </td>
                                    <td>
                                        <v-icon @click="deleteRowA(ix)" color="red"
                                            style="border: 1px solid red; border-radius: 50%">mdi-minus</v-icon>
                                    </td>
                                </tr>
                            </v-fade-transition>
                        </v-simple-table>
                    </v-container>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="green" depressed class="white--text" @click="submit" :loading="btnLoading">{{ $t("storage")
                }}</v-btn>
                <v-btn color="blue darken-1" text @click="clearData">
                    {{ $t('cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
    data() {
        return {
            subjectId: this.$route.params.id,
            btnLoading: false,
            dialog: false,
            structure: {
                name: null,
                time: null,
                mark: null,
                answers: [],
                unit_id: null,
            },
        };
    },
    props: {
        isNew: {
            type: Boolean,
            default: true,
        },
        item: {
            type: Object,
            default: () => {
                return {};
            },
        }
    },
    computed: {
        ...mapGetters({
            units: "subjects/getUnits",
        }),
    },
    methods: {
        ...mapActions({
            fetchUnits: "subjects/fetchUnits",
        }),
        deleteRowA(index) {
            if (this.structure.answers.length == 1) return;
            this.structure.answers.splice(index, 1);
        },
        addNewAnswer(focus = true) {
            this.structure.answers.push({
                is_correct: 0,
                name: null,
                desc: null,
            });
            if (focus) {
                setTimeout(() => {
                    this.ToNextInput();
                }, 0);
            }
        },
        ToNextInput() {
            const inputs = Array.prototype.slice.call(document.querySelectorAll("table input:not([type=hidden])"));
            const currInput = document.activeElement;
            const currInputIndex = inputs.indexOf(currInput);
            const nextinputIndex = currInputIndex + 1;
            const input = inputs[nextinputIndex];
            input.focus();
        },
        async submit() {
            try {
                this.btnLoading = true;
                if (this.isNew) {
                    await axios.post("/lms/question", this.structure);
                    this.$Notifications(
                        this.$t('add success'),
                        { rtl: true, timeout: 2000 },
                        "success"
                    );
                } else {
                    await axios.put(`/lms/question/${this.item.id}`, this.structure);
                    this.$Notifications(
                        this.$t('edit success'),
                        { rtl: true, timeout: 2000 },
                        "success"
                    );
                }
                this.$emit("Submitted");
                this.clearData();
            } catch (err) {
            } finally {
                this.btnLoading = false;
            }
        },
        clearData() {
            this.dialog = false;
            this.structure.name = null,
            this.structure.time = null,
            this.structure.mark = null,
            this.structure.answers = [],
            this.structure.unit_id = null
        },
        async fetchData() {
            this.fetchUnits({ subject_id: this.subjectId, });
            if (this.isNew) {
                this.addNewAnswer(false);
                this.addNewAnswer(false);
            } else {
                const response = await axios.get(`/lms/question/${this.item.id}`);
                this.structure = response.data.data;
                this.structure.unit_id = response.data.data.unit.id
            }
        },
    },
    created() {
    },
};
</script>

<style scoped lang="scss">
table {
    border-collapse: collapse;
    table-layout: fixed;
}

.tableId {
    border-radius: 10%;
}

td,
th {
    padding: 0px 10px;
    border-collapse: collapse;
    border-radius: 10%;
}

table,
th,
td {
    border: 1px solid #000;
    border-collapse: collapse;
    border-radius: 10%;
}

#idAutoComp {
    padding-left: 70px;
    padding-right: 70px;
}

#idAutoCompA {
    padding-left: 120px;
    padding-right: 120px;
}


#idPrice {
    padding-left: 40px;
    padding-right: 40px;
}

#buffet-table {
    font-size: 1rem;
    margin-top: 2%;
}

#buffet-table th {
    color: #909399;
    border: 1px solid rgb(216 216 221 / 53%);
    font-size: 1rem;
    text-align: center;
    width: max-content;
}

#buffet-table tr {
    transition: 0.5s;
}

#buffet-table td {
    border: 1px solid rgb(216 216 221 / 53%);
    text-align: center;
    font-size: 1.1rem;
    width: max-content;
}

.add-user__chip1 {
    border: 1px solid #757575;
    border-radius: 10px;
    margin: 5px 0;
    width: 100%;
}

.columnSelector:hover {
    border-right: 2px solid blue;
}

.cardd {
    border: 1px solid #a2a2a2;
    width: 100%;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 4px 4px;
}

.cardd .preview-img {
    width: 100%;
}
</style>