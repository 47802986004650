<template>
    <div>
        <v-container class="py-10">
            <v-row>
                <v-col md="12" class="d-flex pl-5">
                    <addOuestionLms @Submitted="fetchData(1)" />
                </v-col>
                <v-col md="3">
                    <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.from-date')" type="date"
                        v-model="filters.from">
                    </v-text-field>
                </v-col>
                <v-col md="3">
                    <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.to-date')" type="date"
                        v-model="filters.to">
                    </v-text-field>
                </v-col>
                <v-col md="3">
                    <v-autocomplete   :dark="$store.state.isDarkMode" v-model="filters.unit_id" :items="units"
                        :label="$t('Unit')" item-text="name" item-value="id" color="var(--bg-color--font)"></v-autocomplete>
                </v-col>               
                <v-col md="6">
                    <v-text-field  dense :dark="$store.state.isDarkMode" :label="$t('Question Text')"
                            v-model="filters.name"></v-text-field>
                </v-col>
                <v-col md="3">
                    <v-text-field  dense :dark="$store.state.isDarkMode" :label="$t('Answer')"
                            v-model="filters.answer"></v-text-field>
                </v-col>
                <v-col md="1" class="d-flex justify-center align-center">
                    <v-icon color="var(--bg-font--color)" class="mr-2" @click="fetchData(1)">mdi-magnify</v-icon>
                </v-col>
                <v-col md="12">
                    <app-base-table :tableOptions="tableOptions" @re-fetch-data="fetchData"
                        @re-fetch-paginated-data="fetchData($event)" :enableDelete="false">
                        <template slot="editFeature" slot-scope="{ row }">
                            <deleteItem path="lms/question" :id="row.id" @record-deleted="fetchData(1)"></deleteItem>
                            <addOuestionLms :is-new="false" @Submitted="fetchData(1)" :item="row" />
                        </template>
                    </app-base-table>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import addOuestionLms from "./addOuestionLms.vue";
import deleteItem from "../../../../components/core/delete.vue";
import axios from "axios";
export default {
    components: { addOuestionLms, deleteItem, },
    data() {
        return {
            subjectId: this.$route.params.id,
            tableOptions: {
                tableData: [],
                tableHeaders: ["id", "date", "Question Text", "Unit", "Correct Answer","Mark","Time", "settings"],
                accessTableData: [["id"], ["updated_at"], ["name"], ["unit","name"], ["answer"],["mark"],["time"]],
                loading: false,
                tableMeta: {
                    total: 10,
                    page: 1,
                },
            },
            filters: {
                from: null,
                to: null,
                name: null,
                answer: null,
                unit_id: null,
            },
        };
    },
    computed: {
        ...mapGetters({
            units: "subjects/getUnits",
        }),
    },
    methods: {
        ...mapActions({
            fetchUnits: "subjects/fetchUnits",
        }),
        async fetchData(page) {
            this.tableOptions.loading = true;
            const filterTemp = {};
            Object.keys(this.filters).forEach((key) => {
                if (this.filters[key]) filterTemp[key] = this.filters[key];
            });
            let params = {
                size: 10,
                page: page,
                ...filterTemp,
            };
            const response = await axios.get(`/lms/question?subject_id=${this.subjectId}`, { params });
            if (response.status == 200) {
                console.log(response.data.data);
                this.tableOptions.tableData = response.data.data,
                this.tableOptions.tableMeta.total = response.data.meta.total;
                this.tableOptions.tableMeta.page = response.data.meta.current_page;
            }
            this.tableOptions.loading = false;
        },
    },
    created() {
        this.fetchData(1);
        this.fetchUnits({ subject_id: this.subjectId, });
    },
};
</script>
